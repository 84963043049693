var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _vm.existsNolaPublishingUser || _vm.existsNolaNovelUser
          ? _c("div", { staticClass: "section dialog-body" }, [
              _c("div", [
                _vm._v(
                  " なお、ご登録いただいております下記のNola関連サービスは、引き続き同じメールアドレス・パスワードでご利用いただけます。 "
                ),
              ]),
              _c("div", { staticClass: "related-services" }, [
                _vm.existsNolaNovelUser
                  ? _c("div", { staticClass: "font-bold" }, [
                      _vm._v("・Nolaノベル"),
                    ])
                  : _vm._e(),
                _vm.existsNolaPublishingUser
                  ? _c("div", { staticClass: "font-bold" }, [
                      _vm._v("・Nola出版"),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "section dialog-body" }, [
          _c("div", [
            _vm._v(
              "Nolaアカウントの削除で問題ない場合は、下記の同意ボタンを押して削除をお願いします。"
            ),
          ]),
          _c(
            "div",
            { staticClass: "checkbox", on: { click: _vm.onClickCheckbox } },
            [
              _c("checkbox-icon", {
                staticClass: "form-input",
                attrs: { isChecked: _vm.confirmed, fill: "#389bff" },
              }),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(
                  "削除後のアカウント及びデータ復元ができないことに同意する"
                ),
              ]),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "color-danger" },
              [
                _vm._v(
                  " ※ データの引き継ぎなどでの不具合の際は、アカウント削除前に必ず "
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "/contact" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onClickContact.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("お問い合わせ")]
                ),
                _vm._v(" をお願いします。 "),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button font-bold",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button danger font-bold",
              attrs: { disabled: !_vm.confirmed },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(_vm._s(_vm.positive))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h2", [_vm._v("アカウント削除の最終確認")]),
      _c("h3", [
        _c("span", [
          _vm._v(
            "アカウント削除を行うとNolaのアカウント情報と保存データが削除されます。"
          ),
        ]),
        _c("span", { staticClass: "color-danger" }, [
          _vm._v("削除後のデータの復元はできません"),
        ]),
        _c("span", [_vm._v("のでご注意ください。")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }