var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-box" },
        [
          _vm.isOnlyImage
            ? [
                _c(
                  "div",
                  {
                    staticClass: "dialog-image",
                    on: { click: _vm.onPositiveClick },
                  },
                  [_c("img", { attrs: { src: _vm.image } })]
                ),
              ]
            : [
                _c("div", { staticClass: "dialog-header" }, [
                  _c("img", { attrs: { src: _vm.image } }),
                ]),
                _c("div", { staticClass: "dialog-body" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.title))]),
                  _c("div", { staticClass: "text-container" }, [
                    _vm._v(_vm._s(_vm.body)),
                  ]),
                ]),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button default",
                      class: { single: !_vm.enabledNavigationButton },
                      on: { click: _vm.onClickClose },
                    },
                    [_vm._v(" 閉じる ")]
                  ),
                  _vm.enabledNavigationButton
                    ? _c(
                        "button",
                        {
                          staticClass: "button primary",
                          on: { click: _vm.onPositiveClick },
                        },
                        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                      )
                    : _vm._e(),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }