var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isOffline
      ? _c("div", { staticClass: "global-component" }, [
          _c("div", { staticClass: "text" }, [
            _vm._v("オフラインです。ネットワーク接続状況を確認してください。"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }