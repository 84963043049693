/**
 * Data Model
 */

export interface Character {
  novelId: string;
  characterId: string;
  name: string;
  image: string;
  detail: Detail[];
  additionalColumn: AdditionalColumn[];

  createdAt: string;
  updatedAt: string;
}

interface Detail {
  name: string;
  value: string;
}

interface AdditionalColumn {
  name: string;
  label: string;
  content: string;
}

export interface CharacterFolder {
  name: string;
  characterFolderId: string;
  characterKeys: string[];
}

/**
 * Request Model
 */

export interface ListCharacterInFolder {
  novelId: string;
  characterFolderId: string;
}

export interface CreateCharacter extends Character {
  characterFolderId: string;
}

export interface UpdateCharacter extends Character {}

export interface DeleteCharacter {
  novelId: string;
  characterId: string;
}

export interface CreateCharacterFolder {
  novelId: string;
  name: string;
}

export interface UpdateCharacterFolder {
  novelId: string;
  characterFolders: CharacterFolder[];
}

export interface SelectCharacterFolder {
  novelId: string;
  characterFolderId: string;
}

export interface CopyCharacter extends Character {
  copySource: string;
  destinationKey: string;
}
