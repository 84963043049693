var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "checkbox-icon", on: { click: _vm.toggleCheck } },
    [
      _vm.isChecked
        ? _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24",
                height: _vm.iconSize,
                width: _vm.iconSize,
                fill: _vm.fill,
              },
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z",
                },
              }),
            ]
          )
        : _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24",
                height: _vm.iconSize,
                width: _vm.iconSize,
                fill: _vm.fill,
              },
            },
            [
              _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
              _c("path", {
                attrs: {
                  d:
                    "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z",
                },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }