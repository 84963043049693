// //////////////////
// Data Model
// //////////////////

/** 原稿診断モデル */
export interface ManuscriptAnalysis {
  /** ユーザーID */
  userId: string;
  /** 作品ID */
  novelId: string;
  /** 原稿診断ID */
  manuscriptAnalysisId: string;
  /** 診断対象の原稿ID */
  manuscriptKeys: string[];
  /** 診断結果 */
  manuscriptAnalysisResult?: string;
  /** リクエストが完了したか */
  completed: boolean;
  /** 処理中にエラーがあったか */
  isError?: boolean;
  /** 作成日 */
  createdAt?: string;
  /** 更新日 */
  updatedAt?: string;
}

/** 原稿診断一覧モデル */
export interface ManuscriptAnalysisList {
  items: ManuscriptAnalysis[];
}

/** 診断結果表示用 */
export interface AnalysisResult {
  /** 診断結果 */
  manuscriptAnalysisResult: string;
  /** 診断作成日 */
  createdAt: string;
}

// //////////////////
// Request Model
// //////////////////

/** 原稿診断取得 */
export interface GetManuscriptAnalysisInput {
  novelId: string;
  manuscriptAnalysisId: string;
}

/** 原稿診断作成 */
export interface CreateManuscriptAnalysisInput {
  novelId: string;
  manuscriptKeys: string[];
}
