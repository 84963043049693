var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "message-box dialog-mask" }, [
    _c(
      "div",
      {
        staticClass: "dialog-content",
        attrs: { "hide-footer": "", title: "プリセット" },
      },
      [
        _c(
          "div",
          {
            ref: "contents",
            staticClass: "modal-content",
            on: { scroll: _vm.onScroll },
          },
          [
            _vm._l(_vm.presetImages, function (image, index) {
              return [
                _c(
                  "a",
                  {
                    key: image.key,
                    attrs: {
                      href: "#",
                      "data-index": index,
                      "data-image": image.key,
                      "data-load": "false",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selected(image)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "img-character",
                      attrs: {
                        src: image.data || _vm.loadingImage,
                        alt: "img",
                      },
                    }),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c("div", [
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { block: "" },
                on: { click: _vm.cancel },
              },
              [_vm._v("閉じる")]
            ),
            _vm._v(" 画像元：  ( "),
            _c(
              "a",
              { attrs: { href: "http://www.jewel-s.jp/", target: "blank" } },
              [_vm._v("JewelSaviorFREE")]
            ),
            _vm._v(" ) "),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }