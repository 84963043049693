// //////////////////
// Data Model
// //////////////////

export interface WritingData {
  duration: Duration;
  date: string;
  novelId?: string;
  analyticsValue: number;

  createdAt: string;
  updatedAt: string;
}

type Duration = "daily" | "monthly";

// //////////////////
// Request Model
// //////////////////

export interface WritingDataGetInput {
  duration: Duration;
  date: string;
  novelId?: string;
}

export interface WritingDataListInput {
  duration: Duration;
  startDate: string;
  endDate: string;
  novelId?: string;
}
