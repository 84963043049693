/**
 * Data Model
 */

export interface WorldView {
  novelId: string;
  worldViewId: string;
  name: string;
  image?: string;
  detail: Detail[];
  additionalColumn?: AdditionalColumn[];

  createdAt: string;
  updatedAt: string;
}

interface Detail {
  name: string;
  value: string;
}

interface AdditionalColumn {
  name: string;
  label: string;
  content: string;
}

export interface WorldViewFolder {
  name: string;
  worldViewFolderId: string;
  worldViewKeys: string[];
}

/**
 * Request Model
 */

export interface ListWorldViewInFolder {
  novelId: string;
  worldViewFolderId: string;
}

export interface CreateWorldView extends WorldView {
  worldViewFolderId: string;
}

export interface UpdateWorldView extends WorldView {}

export interface DeleteWorldView {
  novelId: string;
  worldViewId: string;
}

export interface CreateWorldViewFolder {
  novelId: string;
  name: string;
}

export interface UpdateWorldViewFolder {
  novelId: string;
  worldViewFolders: WorldViewFolder[];
}

export interface SelectWorldViewFolder {
  novelId: string;
  worldViewFolderId: string;
}
