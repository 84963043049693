/**
 * Data Model
 */

export interface CorrelationGroup {
  novelId: string;
  correlationGroupId: string;
  name: string;
  diagram?: Diagram;

  createdAt?: string;
  updatedAt?: string;
}

export interface Diagram {
  correlationKeys: string[];
  coordinate: CharacterCoordinate[];
}

interface CharacterCoordinate {
  characterId: string;
  x: number;
  y: number;
}

export interface Correlation {
  novelId: string;
  correlationId: string;
  correlationGroupId: string;
  characterA: string;
  characterB: string;
  relationshipAtoB?: CharacterRelationship;
  relationshipBtoA?: CharacterRelationship;
  memo?: string;

  createdAt?: string;
  updatedAt?: string;
}

interface CharacterRelationship {
  relationship: string;
  color: string;
}

/**
 * Request Model
 */

export interface ListCorrelation {
  novelId: string;
  correlationGroupId: string;
}
