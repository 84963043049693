var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "message-box dialog-mask", on: { click: _vm.focusOut } },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("header", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "dialog-body" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
        ]),
        _c("footer", [
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button cancel",
                on: {
                  click: function ($event) {
                    return _vm.$close(false)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.cancelButton))]
            ),
            _c(
              "button",
              {
                staticClass: "button danger",
                on: {
                  click: function ($event) {
                    return _vm.$close(true)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.okButton))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }