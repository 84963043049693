var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "router-view",
        { attrs: { name: "template" } },
        [
          _c("router-view", {
            attrs: { slot: "header", name: "header" },
            slot: "header",
          }),
          _c("router-view", {
            attrs: { slot: "side", name: "side" },
            slot: "side",
          }),
          _c("router-view"),
        ],
        1
      ),
      _c("global-component"),
      _c("dialogs-wrapper", {
        attrs: { "wrapper-name": "default", "transition-name": "fade" },
      }),
      _c("notifications", {
        attrs: { classes: "custom-vue-notification", ignoreDuplicates: true },
      }),
      _c("notifications", {
        attrs: {
          classes: "custom-vue-notification exception",
          group: "exception",
          position: "top center",
          width: "70%",
          ignoreDuplicates: true,
        },
      }),
      _c("notifications", {
        attrs: {
          group: "deprecated",
          position: "bottom center",
          width: "100%",
          ignoreDuplicates: true,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "custom-vue-notification deprecated",
                    class: item.type,
                    on: {
                      click: function ($event) {
                        return _vm.close(item.id)
                      },
                    },
                  },
                  [
                    _c("warning-icon", { staticClass: "warning-icon" }),
                    _c("div", { staticClass: "notification-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("img", {
                      staticClass: "close-icon",
                      attrs: {
                        src: require("@/assets/img/icon/close_white.svg"),
                        alt: "close",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }