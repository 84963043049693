var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "message-box dialog-mask" }, [
    _c("div", { staticClass: "dialog-content" }, [
      _c("header", [_vm._v("オプション機能")]),
      _c("div", { staticClass: "dialog-body" }, [
        _c("div", [
          _c("hr"),
          _c("h2", [_vm._v("◉ 執筆モードを切り替える")]),
          _c("p", [
            _vm._v(
              "ダークモードなどに執筆モードを切り替えて、利用することができます。"
            ),
          ]),
          _c(
            "select",
            {
              domProps: { value: _vm.theme },
              on: {
                change: function ($event) {
                  return _vm.onSelectColorTheme($event)
                },
              },
            },
            [
              _c("option", { attrs: { value: "", disabled: "" } }, [
                _vm._v("カラーを選択"),
              ]),
              _c("option", { attrs: { value: "default" } }, [
                _vm._v("ノーマル"),
              ]),
              _c("option", { attrs: { value: "dark" } }, [
                _vm._v("ダークモード"),
              ]),
              _c("option", { attrs: { value: "sepia" } }, [
                _vm._v("セピアモード"),
              ]),
              _c("option", { attrs: { value: "blackboard" } }, [
                _vm._v("黒板モード"),
              ]),
              _c("option", { attrs: { value: "bold" } }, [
                _vm._v("太字モード"),
              ]),
            ]
          ),
          _c("hr"),
        ]),
        _c(
          "button",
          {
            staticClass: "dialog-body-close",
            on: {
              click: function ($event) {
                return _vm.$close()
              },
            },
          },
          [_vm._v("閉じる")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }