var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full w-full flex flex-column" },
    [
      _vm._t("header"),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "grid" },
          [_vm._t("side"), _vm._t("default")],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }