var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("header"),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "novel-template" },
          [
            _vm._t("side"),
            _c(
              "main",
              { staticClass: "main" },
              [
                _c("transition", { attrs: { name: "fade-fast" } }, [
                  _c(
                    "div",
                    { staticClass: "flex-1 height-full" },
                    [_vm._t("default")],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }